<template>
  <div class="w-full h-full relative">
    <div
      v-if="isMobileOrTablet"
      class="h-[50px] w-full sticky top-0 text-white bg-nav_color  z-30 rounded-bl-[20px] rounded-br-[20px]"

      :style="`background-color: ${color}`"
    >
      <slot name="header" />
    </div>
    <slot name="body" :small="isMobileOrTablet" />
  </div>
</template>

<script setup lang="ts">
const { isMobileOrTablet } = useDevice()
withDefaults(
  defineProps<{
    color?: string
  }>(),
  {
    color: ''
  }
)

</script>
